<template>
	<div class="homeSet">
		<div class="homeSet-box">
			<div class="box-item">
				<div class="item-title">显示</div>
				<el-switch v-model="open_banner" active-color="#fa8919"></el-switch>
			</div>
		</div>
		<div class="homeSet-box" v-for="(item, index) in bannerList" :key="index">
			<div class="box-item">
				<div class="item-title">轮播图</div>
				<el-upload class="avatar-uploader" action="" :show-file-list="false"
					:before-upload="function (file) { return beforeAvatarUpload(file, item) }">
					<img v-if="item.img" :src="item.img" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div class="box-item">
				<div class="item-title">跳转链接</div>
				<div class="item-b" style="display: flex;">
					<el-radio-group v-model="item.urlType">
						<el-radio :label="999">不跳转</el-radio>
						<el-radio :label="0">清仓秒杀</el-radio>
						<el-radio :label="1">秒杀</el-radio>
						<el-radio :label="2">清仓</el-radio>
						<el-radio :label="3">商品关键字</el-radio>
					</el-radio-group>
					<el-input style="margin-top: 15px;" v-if="item.urlType == 3" v-model="item.goods_name"
						placeholder="请输入商品关键字"></el-input>
				</div>
			</div>
			<div class="box-tab">
				<div class="tab-i" @click.stop="moveUp(index)" v-if="index !== 0">上移</div>
				<div class="tab-i" @click.stop="moveDown(index)" v-if="index !== bannerList.length - 1">下移</div>
				<div class="tab-i" @click.stop="removeBanner(item)">删除</div>
			</div>
		</div>
		<div class="homeSet-add" @click.stop="addBanner">
			+添加新轮播
		</div>
		<div class="homeSet-footer">
			<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
		</div>
		<el-dialog title="封面裁剪" ref="dialog" width="40%" :visible.sync="dialogVisible" :close-on-click-modal="false">
			<div class="cropper-w">
				<div class="cropper" :style="{ width: '100%', height: '280px' }">
					<vueCropper ref="cropper" :img="option.img" :outputSize="option.size"
						:outputType="option.outputType" :info="option.info" :full="option.full"
						:canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original"
						:autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth"
						:autoCropHeight="option.autoCropHeight" :fixedBox="option.fixedBox"></vueCropper>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click.stop="handleConfirm">确认</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		VueCropper
	} from 'vue-cropper'
	export default {
		components: {
			VueCropper,
		},
		data() {
			return {
				open_banner: true,
				bannerList: [{
					img: '',
					url: '',
					urlType: 999,
					goods_name: ''
				}],
				bannerItem: {},
				dialogVisible: false,
				option: {
					img: "", // 裁剪图片的地址
					info: true, // 裁剪框的大小信息
					outputSize: 0.8, // 裁剪生成图片的质量
					outputType: "jpeg", // 裁剪生成图片的格式
					canScale: false, // 图片是否允许滚轮缩放
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 690, // 默认生成截图框宽度
					autoCropHeight: 230, // 默认生成截图框高度
					fixedBox: true, // 固定截图框大小 不允许改变
					fixed: true, // 是否开启截图框宽高固定比例
					fixedNumber: [7, 5], // 截图框的宽高比例
					full: true, // 是否输出原图比例的截图
					canMoveBox: true, // 截图框能否拖动
					original: true, // 上传图片按照原始比例渲染
					centerBox: false, // 截图框是否被限制在图片里面
					infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
				},
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/home/get_homepage_set`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						data.open_banner == 0 ? this.open_banner = false : this.open_banner = true
						if (data.banner.length <= 0) {
							this.bannerList = [{
								img: '',
								url: '',
								urlType: 999,
								goods_name: ''
							}]
						} else {
							this.bannerList = JSON.parse(data.banner)
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 添加
			addBanner() {
				this.bannerList.push({
					img: '',
					url: '',
					urlType: 999,
					goods_name: ''
				})
			},
			// 上移
			moveUp(index) {
				if (index === 0) return;
				const temp = this.bannerList[index];
				this.bannerList[index] = this.bannerList[index - 1];
				this.bannerList[index - 1] = temp;
				this.$forceUpdate(); // 强制更新视图
			},
			// 下移
			moveDown(index) {
				if (index === this.bannerList.length - 1) return;
				const temp = this.bannerList[index];
				this.bannerList[index] = this.bannerList[index + 1];
				this.bannerList[index + 1] = temp;
				this.$forceUpdate(); // 强制更新视图
			},
			// 删除
			removeBanner(item) {
				let index = this.bannerList.indexOf(item)
				this.bannerList.splice(index, 1)
			},
			// 上传
			beforeAvatarUpload(file, item) {
				this.filename = file.name;
				this.bannerItem = item
				this.openCropper(file);
				return false;
			},
			openCropper(file) {
				var _this = this;
				var reader = new FileReader();
				reader.onload = (e) => {
					let data;
					if (typeof e.target.result === "object") {
						// 把Array Buffer转化为blob 如果是base64不需要
						data = window.URL.createObjectURL(new Blob([e.target.result]));
					} else {
						data = e.target.result;
					}

					_this.option.img = data;
					_this.dialogVisible = true;
				};
				// 转化为base64
				reader.readAsDataURL(file);
				// 转化为blob
				// reader.readAsArrayBuffer(file);
			},
			handleConfirm() {
				console.log(this.$refs.cropper);
				this.$refs.cropper.getCropBlob((data) => {
					let blob = window.URL.createObjectURL(data);
					this.downImg = blob;
					var base64;
					var img = new Image();
					img.src = blob;
					var _that = this;
					img.onload = function() {
						var that = this;
						//生成比例
						var w = that.width,
							h = that.height,
							scale = w / h;
						h = w / scale;
						//生成canvas
						var canvas = document.createElement("canvas");
						var ctx = canvas.getContext("2d");
						canvas.width = w;
						canvas.height = h;
						ctx.drawImage(that, 0, 0, w, h);
						// 生成base64
						_that.cropperPic = canvas.toDataURL("image/jpeg", 0.8);
						let files = _that.transformToFiles(_that.cropperPic, _that.filename);
						_that.temporaryCloseCropper = true;
						// 接口请求
						let param = new FormData();
						param.append("file", files, _that.filename);
						_that.$http.post('erp/v1/common/upload', param).then((res) => {
							const {
								code,
								data,
								msg
							} = res.data
							if (code == 200) {
								_that.bannerItem.img = res.data.data.path
								_that.dialogVisible = false
							} else {
								this.$message.error(msg);
							}
						});
					};
				});
			},
			// base64转成files
			transformToFiles(dataurl, filename) {
				var arr = dataurl.split(","),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], filename, {
					type: mime
				});
			},
			// 确定
			confirmFn() {
				for (var i = 0; i < this.bannerList.length; i++) {
					if (!this.bannerList[i].img) {
						this.$message.error('请上传轮播图');
						return
					}
				}
				this.$http.post(`/erp/v1/home/save_banner`, {
					banner: JSON.stringify(this.bannerList),
					open_banner: this.open_banner == true ? 1 : 0,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success(msg);
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.homeSet {
		.homeSet-box {
			background: #fff;
			border-radius: 6px;
			padding: 20px;
			margin-bottom: 20px;

			.box-item {
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				.item-title {
					width: 100px;
					font-size: 14px;
				}

				.item-b {
					display: flex;
					flex-direction: column;
				}
			}

			.box-tab {
				display: flex;
				justify-content: flex-end;
				margin-top: 15px;

				.tab-i {
					font-size: 15px;
					border: 1px solid #C8C8C8;
					padding: 2px 10px;
					border-radius: 15px;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}

		.homeSet-add {
			background: #fff;
			border-radius: 6px;
			padding: 20px;
			text-align: center;
			color: #fa8919;
			cursor: pointer;
			margin-bottom: 20px;
		}

		.homeSet-footer {
			display: flex;
			justify-content: center;

		}

	}
</style>
<style lang='scss'>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 520px;
		height: 175px;
		line-height: 175px;
		text-align: center;
	}

	.avatar {
		width: 520px;
		height: 175px;
		display: block;
	}
</style>